import { Heading, Illustrations, Link, Paragraph } from '@loveholidays/design-system';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { Usp as UspType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';
import { Pictogram } from '@DesignSystemComponents/Pictogram';

interface UspProps extends UspType {
  size?: 'Default' | 'Small';
}

const UspStyles: SxStyleProp = {
  display: 'block',
  textDecoration: 'none',
  color: 'iconDimmedheavy',
  position: 'relative',
  '&:hover, :visited': {
    color: 'iconDimmedheavy',
  },
};

const UspContent: React.FC<Omit<UspProps, 'link' | 'id'> & ClassNameProps> = ({
  description,
  title,
  illustration,
  iconBackgroundColor,
  className,
  size = 'Default',
}) => {
  const iconColor = 'backgroundLightsubtle';

  return (
    <div
      className={className}
      sx={{
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
      }}
    >
      <Pictogram
        size={size === 'Default' ? '64' : '36'}
        name={illustration as unknown as Illustrations}
        backgroundColor={iconBackgroundColor ? iconColor : undefined}
        sx={{
          color: 'iconDefault',
          marginRight: '2xs',
          marginBottom: 0,
        }}
      />
      <div>
        <Heading
          variant="smallbold"
          as="h3"
          sx={{ marginBottom: '5xs' }}
        >
          {title}
        </Heading>
        {description && (
          <Paragraph
            variant="extrasmall"
            sx={{ color: 'iconDimmedheavy' }}
          >
            {description}
          </Paragraph>
        )}
      </div>
    </div>
  );
};

export const Usp: React.FC<Omit<UspProps, 'id'> & ClassNameProps> = ({
  className,
  link,
  ...usp
}) =>
  link ? (
    <WithClickTracking action="usp">
      <Link
        sx={UspStyles}
        className={className}
        href={link.href}
        target={link.target}
        rel={link.rel?.join(' ')}
      >
        <UspContent {...usp} />
      </Link>
    </WithClickTracking>
  ) : (
    <div
      className={className}
      sx={UspStyles}
    >
      <UspContent {...usp} />
    </div>
  );
